import { ref } from 'vue'
import { useHandlePageError } from '../api/model'

export const usePageLoading = (initial = false) => {
  const initialized = ref(initial)
  const error = ref(false)
  const { handlePageError } = useHandlePageError()

  const load = async (handler: () => Promise<void>, errorHandler?: (error: any) => void) => {
    try {
      error.value = false
      initialized.value = false
      await handler()
    } catch (error: any) {
      error.value = true
      console.log(error)
      errorHandler ? errorHandler(error) : handlePageError(error)
    } finally {
      initialized.value = true
    }
  }

  return {
    initialized,
    error,
    load
  }
}

import { useRouter } from 'vue-router'

export const useGoBack = () => {
  const router = useRouter()
  const ignorePages = ['/404', '/500']

  const goBack = () => {
    const back = router.options.history.state.back

    back && !ignorePages.includes(String(back)) ? router?.go(-1) : router.push('/')
  }

  return {
    goBack
  }
}

import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

/**
 * добавляет возможность отменить какое либо действие нативной навигацией
 * @param queryName - имя query-параметра
 * @param popstateHandler - метод, который вызывается при возврате назад
 */
export const useQueryParamsOpenState = (queryName: string, popstateHandler?: () => void) => {
  const route = useRoute()
  const router = useRouter()

  const setParamInRouteQuery = (
    queryValue: string | undefined,
    mode: 'push' | 'replace' = 'push'
  ) => {
    const newRoute = {
      ...route,
      query: { ...route.query, [queryName]: queryValue },
      hash: queryValue && '#ignoreCancel'
    }
    mode === 'push' ? router.push(newRoute) : router.replace(newRoute)
  }

  const popstateListenerHandler = () => {
    popstateHandler && popstateHandler()
  }

  onMounted(() => {
    popstateHandler && window.addEventListener('popstate', popstateListenerHandler)
  })
  onBeforeUnmount(() => {
    popstateHandler && window.removeEventListener('popstate', popstateListenerHandler)
  })

  return {
    setParamInRouteQuery
  }
}
